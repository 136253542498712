import React from 'react';
import Layout from "../../components/layout";

const Bassendean = () => {
    return (
        <Layout>
            <h1>Bassendean</h1>
            <h2>Instructor</h2>
            <p>Sensei Ben Hutton<br/>
                Email: <a href="mailto:admin@togka.com">admin@togka.com</a><br/>
                Website: <a href="https://www.togka.com.au/">www.togka.com.au</a></p>

            <p><strong>Class Times</strong><br/>
                Monday and Wednesday:<br/>
              Junior Grades: 6.00 - 7.00pm<br/>
              Senior Grades: 7:00 - 8:30pm</p>

            <p><strong>Location</strong><br/>
                Bassendean RSL Hall<br/>
                10 Kenny St<br/>
                Bassendean WA 6054</p>

            <p>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3007.7883289609367!2d115.94463161485417!3d-31.904707481244106!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2a32ba1d553b7f29%3A0x60c1811f677df575!2s10%20Kenny%20St%2C%20Bassendean%20WA%206054!5e1!3m2!1sen!2sau!4v1619270466024!5m2!1sen!2sau"
                    width="400" height="300" frameborder="0" title="bassendean"></iframe>
            </p>

        </Layout>);
}

export default Bassendean;
